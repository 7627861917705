import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import {DateTime} from "luxon";


import { CompanyType } from "../redux/api/company";
import {textColor} from "../styles/variables";

function CompanyCard ({
  company: {
    name,
    status,
    tasks,
    prolongationDate,
  }
}: {
  company: CompanyType
}) {
  const boxStyles = {
    width: '25%',
  };

  const dt = prolongationDate ? DateTime.fromFormat(prolongationDate, 'yyyy-MM-dd').toISODate() : 'Not specified';

  return (
    <Card sx={{
      mb: 2,
      width: '100%',
      color: textColor,
    }}>
      <CardContent>
        <Box
          component='div'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              ...boxStyles,
            }}
             component='div'
          >
            {name}
          </Box>
          <Box
            sx={{
              ...boxStyles
            }}
            component='div'
          >
            Status: {status}
          </Box>
          <Box
            component='div'
            sx={{
              ...boxStyles
            }}
          >Tasks</Box>
          <Box
            component='div'
            sx={{
              ...boxStyles
            }}
          >
            Prolongation Date: {dt}
          </Box>

        </Box>

      </CardContent>
    </Card>
  );
}

export default CompanyCard;
