import React from 'react';
import { Link } from 'react-router-dom';
import { Notifications } from "@mui/icons-material";
import styled from 'styled-components';

import {Drawer, List, ListItem, ListItemButton, ListItemText} from '@mui/material';
import {backgroundColorCard, textColor} from '../styles/variables';
import { useUser } from '../hooks/user';
import { logout } from '../utils/api';

function Navigation() {
  const drawerWidth = 200;
  const { isLoggedIn } = useUser();
  if (!isLoggedIn) return null;
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: backgroundColorCard,
          color: textColor,
          position: 'relative',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <UserStyled>
        UserName
        <Notifications className="notifications" style={{
          height: 20,
        }} />
      </UserStyled>
      <List>
        <ListItem key='companies' disablePadding>
          <Link to="/companies">
            <ListItemButton>
              <ListItemText primary='Companies' />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem key='tasks' disablePadding>
          <Link to="/tasks">
            <ListItemButton>
              <ListItemText primary='Tasks' />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem key='logout' disablePadding>
          <ListItemButton onClick={logout}>
            <ListItemText primary='Logout' />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  )
} 

export default Navigation;

const UserStyled = styled.div`
  padding: 8px 16px;
  .notifications {
    position: absolute;
    top: 12px;
    right: 16px;
  }
`;
