import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
  
import { Typography } from "@mui/material";
import { TreeView, TreeItem } from "@mui/x-tree-view";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Save from '@mui/icons-material/Save';

import TaskCard from "../components/TaskCard";
import { useGetCompanyQuery, useGetCompanyDocumentsQuery } from '../redux/api/company';
import { primaryColor } from "../styles/variables";

interface RenderTree {
  id: string;
  name: string;
  children?: readonly RenderTree[];
  downloadUrl?: string;
};

function Company () {
  let { companyId } = useParams();
  const { data } = useGetCompanyQuery({ companyId: companyId as string });
  const { data: documents } = useGetCompanyDocumentsQuery({ companyId: companyId as string });

  if (!data) return null;

  const renderTree = (nodes: RenderTree) => {
    const treeItem = (
    <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name} sx={{ padding: 0 }}>
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>)

    return Array.isArray(nodes.children) || !nodes.downloadUrl ? treeItem : (
      <AStyled href={nodes.downloadUrl} download="pdf" key={nodes.id}>
        <Save />
        {treeItem}
      </AStyled>
    );
  }

  const hasDocuments = documents && documents.length > 0;

  const address = data.address && (
    <div>
      <Typography variant="h4" sx={{ color: primaryColor, pb: 2 }}>
        Address
      </Typography>
      <Typography variant="body1" sx={{ pb: 2 }}>
        {data.address}
      </Typography>
    </div>
  );

  const director = data.director && (
    <div>
      <Typography variant="h4" sx={{ color: primaryColor, pb: 2 }}>
        Directors
      </Typography>
      <Typography variant="body1" sx={{ pb: 2 }}>
        {data.director}
      </Typography>
    </div>
  );

  const shareholders = data.shareholders && (
    <div>
      <Typography variant="h4" sx={{ color: primaryColor, pb: 2 }}>
        Shareholders
      </Typography>
      <Typography variant="body1" sx={{ pb: 2 }}>
        {data.shareholders}
      </Typography>
    </div>
  );

  const incorporationDate = data.incorporationDate && (
    <div>
      <Typography variant="h4" sx={{ color: primaryColor, pb: 2 }}>
        Incorporation Date
      </Typography>
      <Typography variant="body1" sx={{ pb: 2 }}>
        {data.incorporationDate}
      </Typography>
    </div>
  );

  const tasks = data.tasks && (
    <div>
      <Typography variant="h4" sx={{ color: primaryColor, pb: 2 }}>
        Tasks
      </Typography>
      {data.tasks.map((task) => (
        <TaskCard task={task} key={task.externalId} />
      ))}
    </div>
  );

  return (
    <div>
      <Typography variant="h3" sx={{ color: primaryColor, pb: 2 }}>
        Company - {data.name}
      </Typography>
      {tasks}
      {address}
      {director}
      {shareholders}
      {incorporationDate}
      {
        hasDocuments && (
          <>
            <Typography variant="h4" sx={{ color: primaryColor, pb: 2 }}>
              Documents
            </Typography>
            <TreeView
              aria-label="file system navigator"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              {documents.map(n => renderTree(n))}
            </TreeView>
          </>
        )
      }
      
    </div>
  );
}

export default Company;

const AStyled = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  &:hover {
    color: inherit;
  }
`;
