import { mainApi, TAGS } from './base';

export type TaskStep = {
  id: number;
  name: string;
  text: string;
}

export type TaskType = {
  name: string;
  status: string;
  externalId: string;
  company?: {
    name: string;
    externalId: string;
    status: string;
  };
  allStepsOfTask?: { [key: string]: TaskStep };
  currentStep?: string;
}

export type TasksType = {
  tasks:  TaskType[];
}

export const taskApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getTasksData: builder.query<TasksType, void>({
      query: () => `tasks`,
      providesTags: [{ type: TAGS.companies }],
    }),
    getTask: builder.query<
      TaskType,
      { taskId: string; }
    >({
      query: ({ taskId }) => {
        return `tasks/${taskId}`;
      },
      providesTags: [{ type: TAGS.task }],
    }),
  }),
});

export const { useGetTasksDataQuery, useGetTaskQuery } = taskApi;
