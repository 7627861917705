import React from 'react';
import styled from 'styled-components';

function Header() {
  return (<HeaderStyled>Taxus Logo</HeaderStyled>)
} 

export default Header;

const HeaderStyled = styled.div`
  height: 50px;
  position: fixed;
  top: 0;
`;
