import React from 'react';
import { TaskType, useGetTasksDataQuery } from '../redux/api/task';
import { Typography } from "@mui/material";
import { primaryColor } from "../styles/variables";
import TaskCard from "../components/TaskCard";
import { Link } from 'react-router-dom';

function Tasks() {
  const { data } = useGetTasksDataQuery();

  const renderTasks = () => data?.tasks?.map((task: TaskType) => (
    <Link to={`/tasks/${task.externalId}`} key={task.externalId}>
      <TaskCard task={task} />
    </Link>
  ));

  return (
    <div>
      <Typography variant="h1" sx={{ color: primaryColor, pb: 2 }}>
        Tasks
      </Typography>
      {renderTasks()}
    </div>
  );
}

export default Tasks;
