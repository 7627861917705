import { createApi } from '@reduxjs/toolkit/query/react';

import { mainQuery } from './queries';

export enum TAGS {
  companies = 'companies',
  company = 'company',
  task = 'task',
  companyDocuments = 'companyDocuments',
}

export const mainApi = createApi({
  reducerPath: 'mainApi',
  baseQuery: mainQuery,
  tagTypes: Object.values(TAGS),
  endpoints: () => ({}),
  // test code
  keepUnusedDataFor: 1,
});
