import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  token: localStorage.getItem('token') || '',
  refresh_token: localStorage.getItem('refresh_token') || '',
};

type UserType = {
  refresh_token: string;
  token: string;
}

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData(state, action: PayloadAction<UserType>) {
      const { token, refresh_token } = action.payload;
      localStorage.setItem('token', token);
      state.token = token;
      localStorage.setItem('refresh_token', refresh_token);
      state.refresh_token = refresh_token;
      return state;
    },
    clearUserData(state) {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      return state = {
        refresh_token: '',
        token: ''
      }; 
    },
  },
});

const { actions, reducer } = UserSlice;

export const UserReducer = reducer;
export const {
  setUserData,
  clearUserData,
} = actions;
