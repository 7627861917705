import { createTheme } from '@mui/material/styles';
import { backgroundColorCard } from './styles/variables'

// A custom theme for this app
const theme = createTheme({
    palette: {},
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: backgroundColorCard,
          },
        },
      },
    },
  }
);

export default theme;