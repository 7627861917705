import React from 'react';
import styled from 'styled-components';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles';

import { store } from './redux/store'
import Tasks from './views/Tasks';
import Companies from './views/Companies';
import Login from './views/Login';
import Navigation from './components/Navigation';
import Header from './components/Header';
import LoaderOverlay from './components/LoaderOverlay';
import Task from './views/Task';
import Company from './views/Company';
import theme from './theme';
import { useUser } from './hooks/user';

import {
  backgroundColor, textColorSecondary
} from "./styles/variables";

import './styles/styles.scss';

const ProtectedRoute = ({ redirectPath = '/login' }) => {
  const { isLoggedIn } = useUser();
  if (!isLoggedIn) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <MainContainer>
            <LoaderOverlay />
            <Header />
            <Navigation />
            <Content>
              <Routes>
                <Route element={<ProtectedRoute />}>
                <Route
                    path={'/tasks/:taskId'}
                    element={<Task />}
                  />
                  <Route
                    path={'/tasks'}
                    element={<Tasks />}
                  />
                  <Route
                    path={'/companies/:companyId'}
                    element={<Company />}
                  />
                  <Route
                    path={'/companies'}
                    element={<Companies />}
                  />
                </Route>
              <Route
                  path={'/login'}
                  element={<Login />}
                />
                <Route
                  path="*"
                  element={<Navigate to="/login" />}
                />
              </Routes>
            </Content>
          </MainContainer>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;

export const MainContainer = styled.div`
  min-height: 100vh;
  position: relative;
  background-color: ${backgroundColor};
  display: flex;
  padding-top: 50px;
    color: ${textColorSecondary};
`;

export const Content = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 24px;
`;
