import axios from "./axios";

import { store } from '../redux/store';
import { setUserData, clearUserData } from '../redux/user/slice';
import { setAppLoading } from '../redux/app/slice';
import { history } from './routing';

const {
  REACT_APP_API_URL,
} = process.env;

export const refreshToken = () => {
  const refresh_token = localStorage.getItem('refresh_token');

  store.dispatch(setAppLoading(true));

  return axios
    .post(REACT_APP_API_URL + '/token/refresh', { refresh_token })
    .then((response) => {
      store.dispatch(setUserData(response.data));
      console.log(response.data);
    })
    .catch((error) => {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      store.dispatch(clearUserData());
      history.navigate('/login');
    })
    .finally(() => {
      store.dispatch(setAppLoading(false));
    });
}

export const login = (username, password) => {
  return axios
    .post(REACT_APP_API_URL + "/login", {
      username,
      password,
    })
    .then((response) => {
      
      if (response.data.token) {
        store.dispatch(setUserData(response.data));
      }

      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const logout = () => {
  store.dispatch(clearUserData());
  history.navigate('/login');
};
