import React from 'react';
import { useParams } from 'react-router-dom';

import { useGetTaskQuery } from '../redux/api/task';
import { Typography } from "@mui/material";

import { primaryColor } from "../styles/variables";
import TaskStepper from "../components/TaskStepper";
import { TaskStep } from "../redux/api/task";

type RegisterCompanyType = {
  currentStep?: string;
  allStepsOfTask?: { [key: string]: TaskStep };
};

function RegisterCompany ({
  currentStep,
  allStepsOfTask,
}: RegisterCompanyType) {
  return (
    <>
      <TaskStepper 
        allStepsOfTask={allStepsOfTask}
        currentStep={currentStep}
      />
    </>
  );
};

function Task () {
  let { taskId } = useParams();
  const { data } = useGetTaskQuery({ taskId: taskId as string });

  if (!data) return null;

  const {
    allStepsOfTask,
    currentStep,
  } = data;

  // const renderCompanyBlock = () => {
  //   if (!company) return null;

  //   return (
  //     <div>
  //       <Typography variant="h4" sx={{ color: primaryColor, pb: 2 }}>
  //         Company - {company.name} - <Chip label={company.status} color="primary" />
  //       </Typography>
  //     </div>
  //   );
  // }

  const content = <RegisterCompany allStepsOfTask={allStepsOfTask} currentStep={currentStep} />;

  return (
    <div>
      <Typography variant="h3" sx={{ color: primaryColor, pb: 2 }}>
        Task - {data?.name}
      </Typography>
      {content}
      {/* {renderCompanyBlock()} */}
    </div>
  );
}

export default Task;
