import { fetchBaseQuery } from '@reduxjs/toolkit/query';

const {
  REACT_APP_API_URL,
} = process.env;

export const mainQuery = fetchBaseQuery({
  baseUrl: REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('token');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});
