import React, { useState } from "react";
import styled from "styled-components";

import { Typography } from "@mui/material";
import { primaryColor, textColorSecondary } from "../styles/variables";
import { TaskStep } from "../redux/api/task";

type TaskStepperType = {
  allStepsOfTask?: { [key: string]: TaskStep };
  currentStep?: string;
};

const TaskStepper = ({
  allStepsOfTask,
  currentStep,
}: TaskStepperType) => {
  const [activeStep, setActiveStep] = useState(currentStep as string);
  if (!allStepsOfTask || !currentStep) return null;
  const stepName = (allStepsOfTask[activeStep]).name;
  const stepText = (allStepsOfTask[activeStep]).text;
  return (
    <TaskStepperStyled>
      <Typography variant="h4" sx={{
        color: primaryColor,
        pb: 2,
        textAalign: 'center',
      }}>
        Roadmap
      </Typography>
      {Object.keys(allStepsOfTask).map((key) => {
        const {
          name,         
        } = allStepsOfTask[key];
        const color = key === activeStep ? primaryColor : '#fff';
        const activeStepText = key === currentStep ? <span className="active-step-text"> - We are here</span> : null;
        return (
          <Typography variant="body1" sx={{ color, cursor: 'pointer', }} key={key} onClick={() => setActiveStep(key)}>
            {name} {activeStepText}
          </Typography>
        );
      })}
      <DescriptionStyled>
        <div className="name">{stepName}</div>
        <div className="description">{stepText}</div>
      </DescriptionStyled>
    </TaskStepperStyled>
  );
};

export default TaskStepper;

const TaskStepperStyled = styled.div`
  .active-step-text {
    color: ${textColorSecondary};
  }
`;

const DescriptionStyled = styled.div`
  padding: 20px;
  border-left: 4px solid #fff;
  border-radius: 4px;
  color: white;
  margin-top: 20px;
  .name {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .description {

  }
`;
