import { configureStore, combineReducers, Middleware, isRejectedWithValue, MiddlewareAPI } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import { thunk } from 'redux-thunk';

import { mainApi } from './api/base';
import { refreshToken } from '../utils/api';
import { UserReducer as user } from './user/slice';
import { AppReducer as app } from './app/slice';
import { history } from '../utils/routing';
import { setAppLoading } from './app/slice';

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      const refresh_token = localStorage.getItem('refresh_token');
      const token = localStorage.getItem('token');
      if (!token || !refresh_token) return history.navigate('/login');
      const pathToNavigate = history.location.pathname;
      refreshToken()
        .then(() => {
          history.navigate('/');
          setTimeout(() => {
            history.navigate(pathToNavigate);
            store.dispatch(setAppLoading(false));
          }, 0);
        });
    }

    return next(action)
  }

const middlewares = [thunk as Middleware, rtkQueryErrorLogger];

const rootReducer = combineReducers({
  user,
  app,
  [mainApi.reducerPath]: mainApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      immutableCheck: false,
      serializableCheck: false,
    }).concat([
      ...middlewares,
      mainApi.middleware,
    ]),
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
