import axios from 'axios';

export const axiosDefault = axios.create();

// const getToken = () => localStorage.getItem('token');

axiosDefault.interceptors.request.use((config) => {
  // const token = getToken();
  return config;
});

export default axiosDefault;
