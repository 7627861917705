import { mainApi, TAGS } from './base';


export type CompanyType = {
  externalId: string;
  name: string;
  status: string;
  tasks: {
    name: string;
    status: string;
    externalId: string;
  }[];
  prolongationDate: 'string';
  address: string;
  director: string;
  incorporationDate: string;
  shareholders: 'string';
};

export type CompaniesType = {
  companies: CompanyType[];
};
export type CompanyDocumentsType = {
  id: string;
  name: string;
  downloadUrl?: string;
};

export const companyApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompaniesData: builder.query<CompaniesType, void>({
      query: () => `companies`,
      providesTags: [{ type: TAGS.companies }],
    }),
    getCompany: builder.query<
      CompanyType,
      { companyId: string; }
    >({
      query: ({ companyId }) => {
        return `companies/${companyId}`;
      },
      providesTags: [{ type: TAGS.company }],
    }),
    getCompanyDocuments: builder.query<
      CompanyDocumentsType[],
      { companyId: string; }
    >({
      query: ({ companyId }) => {
        return `files/company/${companyId}`;
      },
      providesTags: [{ type: TAGS.companyDocuments }],
    }),
  }),
});

export const { useGetCompaniesDataQuery, useGetCompanyQuery, useGetCompanyDocumentsQuery } = companyApi;
