import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';


import {textColor} from "../styles/variables";
import {TaskType} from "../redux/api/task";

function TaskCard ({
  task: {
    name,
    status
  }
}: {
  task: TaskType
}) {
  const boxStyles = {
    width: '25%',
  };

  return (
    <Card sx={{
      mb: 2,
      width: '100%',
      color: textColor,
    }}>
      <CardContent>
        <Box
          component='div'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              ...boxStyles,
            }}
             component='div'
          >
            {name}
          </Box>
          <Box
            sx={{
              ...boxStyles
            }}
            component='div'
          >
            Status: {status}
          </Box>
        </Box>

      </CardContent>
    </Card>
  );
}

export default TaskCard;
