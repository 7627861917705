import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';

import { shallowEqual, useSelector } from 'react-redux'

import { login } from '../utils/api';

const {
  REACT_APP_DEFAULT_LOGIN,
  REACT_APP_DEFAULT_PASSWORD,
} = process.env;

function Login () {
  const isLoggedIn = useSelector((state: any) => !!state.user.token, shallowEqual)
  const [email, setEmail] = useState(REACT_APP_DEFAULT_LOGIN);
  const [password, setPassword] = useState(REACT_APP_DEFAULT_PASSWORD);

  if (isLoggedIn) {
    return <Navigate to='/companies' replace />;
  }

  const handleLogin = () => {
    login(email, password);
  }

  return (
    <Card sx={{
      mb: 2,
      display: 'flex',
      flexDirection: 'column',
      width: '400px',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '20px',
      backgroundColor: '#fff',
    }}>
      <TextField
        id="standard-basic" 
        label="Email" 
        variant="standard" 
        onChange={e => setEmail(e.target.value)} 
        value={email}
        sx={{ pb: 2 }}
      />
      <TextField 
        id="standard-basic" 
        type="pasword" 
        label="Password" 
        variant="standard" 
        onChange={e => setPassword(e.target.value)} 
        value={password}
        sx={{ pb: 4 }}
      />
      <Button 
        variant="contained" 
        color="success" 
        type='submit' 
        onClick={handleLogin}
      >
        Login
      </Button>
    </Card>
  );
}

export default Login;
