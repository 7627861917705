import React from 'react';
import { Link } from 'react-router-dom';

import { CompanyType, useGetCompaniesDataQuery } from '../redux/api/company';
import {Typography} from "@mui/material";

import CompanyCard from "../components/CompanyCard";
import {primaryColor} from "../styles/variables";



function Companies () {
  const { data } = useGetCompaniesDataQuery();
  const renderCompanies = () => data?.companies?.map(
    (company: CompanyType) => (
      <Link to={`/companies/${company.externalId}`} key={company.externalId}>
        <CompanyCard company={company} />
      </Link>
    )
  );
  return (
    <div>
      <Typography variant="h1" sx={{ color: primaryColor, pb: 2 }}>
        Companies
      </Typography>
      {renderCompanies()}
    </div>
  );
}

export default Companies;
