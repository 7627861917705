import React from 'react';
import styled from 'styled-components';
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom';

import { history } from '../utils/routing';


function LoaderOverlay() {
  history.navigate = useNavigate();
  history.location = useLocation();
  const isLoading = useSelector((state: any) => state.app.isLoading, shallowEqual);
  if (!isLoading) return null;
  return (<LoaderOverlayStyled />)
} 

export default LoaderOverlay;

const LoaderOverlayStyled = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
`;
