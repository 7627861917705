type HistoryType = {
  navigate: (path: string) => void;
  location: {
    pathname: string;
  };
}
export const history: HistoryType = {
  navigate: () => {},
  location: {
    pathname: '/',
  },
};
